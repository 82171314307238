<template>
  <div class="recommended-counselling">
    <template v-if="showFlowQuery">
      <FlowResolver
        :flow="flow"
        :router="$router"
        :route="$route"
        :lang="language.lang"
        :callback="changeview"
        v-if="showFlow"
      />
    </template>
    <template v-else>
      <PageContainer
        :title="$translations.buttons['schedule-orientation']"
        :back="actions.back"
        :submit="actions.submit"
      >
        <template v-slot:content>
          <div class="segment">
            <div class="segment-content">
              <div class="body" v-html="traslationLocal.description" />
            </div>
          </div>

          <div
            class="page-segment margin-top"
            v-if="availableDateTimesRaw && !appointment"
          >
            <div class="page-segment-content" v-if="limit">
              <DateSlotPicker
                v-model="date"
                :available-dates="availableDateTimesRaw"
                :loading="loadingSchedule"
                :lang="language.lang"
                :range="limit"
                :start-date="start.toDate()"
                :end-date="end.toDate()"
                :version="true"
                @date-change="onChanged"
                @load-dates="loadSchedule"
              />

              <div class="margin-top margin-left">
                <TimeZoneSelector class="margin-y" />

                <template v-if="time">
                  <InfoData
                    icon="icon-timer-calendar-off"
                    :text="getBannerText"
                  />
                </template>
                <template v-else>
                  <div
                    class="content-small text-gray-50"
                    v-text="
                      $translations['specialist-page'].availability[
                        'date-banner'
                      ].empty
                    "
                  />
                </template>
              </div>
            </div>
          </div>

          <!-- <div class="page-segment">
            <div class="page-segment-content text-center">
              <div class="body" v-text="traslationLocal['no-availability']" />
              <Button
                type="link"
                text-color="purple"
                :text="traslationLocal['no-availability-button']"
                @clicked="goToTypeform"
              />
            </div>
          </div> -->

          <div class="page-segment">
            <div class="page-segment-content text-center">
              <div
                class="body margin-bottom"
                v-text="traslationLocal['others-options']"
              />
              <MediumCard
                :image="option.img"
                :title="option.title"
                :bottom-line="option.content"
                :showAction="true"
                @clicked="onSelectOption(index)"
                v-for="(option, index) of options"
                :key="index"
              />
            </div>
          </div>
        </template>
      </PageContainer>

      <!-- modals -->
      <FullScreenDialog
        :title="modal.title"
        :subtitle="modal.subtitle"
        :illustration="modal.illustration"
        @close="modal.action"
        v-if="modal.isVisible"
      >
        <template v-slot:body>
          <div>
            <UsersAppointmentCard :appointment="appointment" />

            <Button
              class="margin-y"
              @clicked="openVideoCallRecommendations"
              :text="$translations.success['button/view-recomendations']"
            />
            <Button
              @clicked="$router.push({ name: 'Home' })"
              :text="$translations.success['button/go-home']"
              type="outline"
            />
          </div>
        </template>
      </FullScreenDialog>

      <FullScreenDialog
        v-if="loading"
        :show-icon-loader="true"
        :can-be-closed="false"
        loader-name="selia-spinner"
        :card-style="false"
      >
        <template v-slot:body>
          <div class="recommended-specisliast-spinner">
            <div
              class="heading-mate"
              v-html="traslationLocal['modal-get-specialist'].title"
            />
          </div>
        </template>
      </FullScreenDialog>
    </template>
  </div>
</template>

<script>
import {
  FullScreenDialog,
  IlustrationName,
  PageContainer,
  DateSlotPicker,
  InfoData,
  Button,
  Counseling,
  Settings,
  MediumCard,
  MatchingAgent,
  SearchAll,
  Flow,
  FlowResolver
} from '@seliaco/red-panda'
import UsersAppointmentCard from '@/components/appointment/AppointmentCard'
import TimeZoneSelector from '@/components/fields/TimeZoneSelector.vue'
import { VideoCallRecommendations } from '@/constants/videocall-recommendations'

import { Browser } from '@capacitor/browser'
import {
  AppointmentFlowEvent,
  BookingAndAppointmentDetailEvent
} from '@/types/events'
import { userStatus } from '../../types/state'
import { mapGetters } from 'vuex'

export default {
  name: 'RecommendedCounselling',
  components: {
    InfoData,
    PageContainer,
    FullScreenDialog,
    DateSlotPicker,
    Button,
    UsersAppointmentCard,
    TimeZoneSelector,
    MediumCard,
    FlowResolver
  },
  data () {
    const traslationLocal = this.$translations['recommended-specialist']

    // modal success
    const modal = {
      isVisible: false,
      title: this.$translations.appointments.modals.scheduled.title,
      subtitle: this.$translations.appointments.modals.scheduled.subtitle,
      illustration: IlustrationName.success,
      action: () => this.$router.push({ name: 'Home' })
    }

    // submit button
    const actions = {
      back: {
        callback: () => {
          if (this.$route.query.next) {
            this.$router.replace({
              name: this.$route.query.next,
              query: this.$route.query
            })
          } else if (this.$route.query.back) {
            this.$router.replace({
              path: this.$route.query.back,
              query: this.$route.query
            })
          } else {
            this.$router.replace('/')
          }
        }
      },
      submit: {
        text: traslationLocal.submit,
        callback: () => {
          this.scheduleCounselling()
        },
        disabled: true,
        loading: false
      }
    }

    // options
    const options = {
      sugested: {
        content: traslationLocal['match-content'],
        title: traslationLocal['match-title'],
        img: MatchingAgent
      },
      specialists: {
        content: traslationLocal['specialist-content'],
        title: traslationLocal['specialist-title'],
        img: SearchAll
      }
    }

    return {
      traslationLocal,
      date: new Date(),
      loading: false,
      loadingSchedule: false,
      limit: null,
      availableDateTimes: null,
      availableDateTimesRaw: [],
      time: null,
      timestamp: null,
      start: null,
      end: null,
      appointment: null,
      body: null,
      serviceName: 'Orientacion',
      modal,
      actions,
      options,
      recommendationsUrl: VideoCallRecommendations,
      flow: null,
      showFlow: false,
      showFlowQuery: false
    }
  },
  created () {
    if (this.$route.query?.init) {
      this.showFlowQuery = true
      this.getFlow()
    } else {
      this.getAvailabilityCounselling(new Date(), true)
    }
  },
  methods: {
    async getFlow () {
      this.flow = await Flow.get('COUNSELLING_INIT')
      this.showFlow = true
    },
    changeview () {
      this.showFlowQuery = false
      this.loading = true
      this.loadingSchedule = true
      this.getAvailabilityCounselling(new Date(), true)
    },
    async getAvailabilityCounselling (date, firstLoading) {
      this.loading = true
      this.loadingSchedule = true
      this.limit = await Settings.get('COUNSELING_CALENDAR_RANGE').then(
        (response) => Number(response.parse_value)
      )

      if (firstLoading) {
        this.loading = true
      }
      this.loadingSchedule = true

      this.start = this.$moment(date)
      this.end = this.$moment(date).add(this.limit, 'day')
      Counseling.availability({
        start: this.start.format('YYYY-MM-DD'),
        end: this.end.format('YYYY-MM-DD'),
        timeZone: this.timezone,
        modality: 'VIRTUAL'
      })
        .then((availableDateTimes) => {
          this.availableDateTimes = availableDateTimes
          this.availableDateTimesRaw = availableDateTimes.reduce(
            (accumulator, currentValue) => {
              return accumulator.concat(currentValue.timestamp)
            },
            []
          )
        })
        .catch()
        .finally(() => {
          if (firstLoading) {
            this.loading = false
          }
          this.loadingSchedule = false

          this.body = {
            user: this.user,
            specialist_is_public: true,
            service_name: this.serviceName
          }
          AppointmentFlowEvent.Appointment_View_Date_Time(this.body)
        })
    },
    scheduleCounselling () {
      this.actions.submit.loading = true
      const availableData = this.availableDateTimes.find(
        (time) => this.timestamp === time.timestamp
      )

      Counseling.schedule({
        specialist: availableData.specialistIds[0],
        starts_at: this.time
      })
        .then((appointment) => {
          this.appointment = appointment
          this.$store.commit('auth/SET_USER', {
            ...this.user,
            status: userStatus.waiting_for_orientation
          })
          this.modal.isVisible = true
        })
        .catch()
        .finally(() => {
          BookingAndAppointmentDetailEvent.ScheduleAppointmentSuccessful({
            user: this.$store.getters['auth/user'],
            bookappointment: {
              appointment_id: this.appointment.id,
              patient_name: this.appointment.patient_name,
              specialist_name: `${this.appointment.specialist.user.first_name} ${this.appointment.specialist.user.last_name}`,
              starts_at: this.appointment.starts_at,
              day: this.$moment(this.appointment.starts_at).format(
                'DD/MM/YYYY'
              ),
              time: this.$moment(this.appointment.starts_at).format('HH:mm'),
              service_name: this.appointment.service.name,
              status: this.appointment.status,
              created_at: this.appointment.created_at
            }
          })
          this.actions.submit.loading = false
        })
    },
    onChanged (value) {
      if (value) {
        this.actions.submit.disabled = false
        this.timestamp = value.raw
        this.time = value.code
        AppointmentFlowEvent.Appointment_Select_Date({
          ...this.body,
          date: value.code
        })
        this.$emit('changed', value)
      }
    },
    loadSchedule (value) {
      this.getAvailabilityCounselling(value)
    },
    async openVideoCallRecommendations () {
      await Browser.open({
        url: this.recommendationsUrl[this.language.lang]
      })
    },
    goToTypeform () {
      Settings.get('SELIA_USERS_AVAILABILITY_FORM_URL')
        .then((value) => {
          const url = value.parse_value.replaceAll('{USER_ID}', this.user.id)

          const body = {
            user: this.user,
            phone_number: this.user.phone,
            email: this.user.email
          }
          AppointmentFlowEvent.Appointment_Form_Click(body)
          Browser.open({ url }).catch((e) => {
            this.$toast({
              text: e.message || this.$translations.error.default,
              severity: 'error'
            })
          })
        })
        .catch((e) => {
          this.$toast({
            text: e.message,
            severity: 'error'
          })
        })
    },
    async onSelectOption (index) {
      if (index === 'sugested') {
        this.$store.commit('flow/RESET_MATCHING')
        this.$router.push({
          name: 'QuestionnaireContainer',
          query: {
            redirect: 'matching-choose-specialist'
          }
        })
      }

      if (index === 'specialists') {
        this.$router.push({ name: 'Search' })
      }
    }
  },
  computed: {
    getBannerText () {
      const dateSelected = this.$moment(this.time)
      const today = this.$moment()
      const tomorrow = this.$moment().add(1, 'day')
      const translations =
        this.$translations['specialist-page'].availability['date-banner']

      let day = 'dddd'

      if (dateSelected.isSame(today, 'd')) {
        day = `[${translations.today}]`
      }

      if (dateSelected.isSame(tomorrow, 'd')) {
        day = `[${translations.tomorrow}]`
      }

      const momentFormat = `${day}, D [${translations.of}] MMMM [${translations.at}] hh:mm a`

      return `${translations.appointment} ${dateSelected
        .tz(this.$store.getters.userTimezone)
        .format(momentFormat)}`
    },
    ...mapGetters({
      user: 'auth/user',
      timezone: 'userTimezone',
      language: 'language'
    })
  }
}
</script>

<style lang="sass" scoped>
.recommended-specisliast-spinner
  max-width: 480px
  text-align: center
  margin: auto

::v-deep .flow-resolver
  &-max-width
    .rest-view
      &-content
        img
            margin-bottom: 0px !important
</style>
